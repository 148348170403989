<template>
	<Common
		type="developers"
		@cardClick="redirect"
		@getData="get"
		:list="infoDevelopers"
		:isLoading="isLoading"
		:meta="meta"
	/>
</template>

<script>
import Common from '../modules/list/Common.vue'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import { i18n } from '@/i18n'
import { setCommonMetaData } from '@/utils/setMetaData'

export default {
	name: 'Developers',
	components: { Common },
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.developers')} - OWRealty`, {
			description: i18n.global.t('meta.developersDesc'),
			type: 'article',
			title: i18n.global.t('meta.developersTitle'),
			url: window.location.href
		})
	},
	data() {
		return {
			isLoading: true,
			infoDevelopers: [],
			meta: {},
      processingRequest: false,
		}
	},
	methods: {
		get(fresh = false) {
      if (this.processingRequest) return;

      this.processingRequest = true;

      createRequest(requestConfigs.GETDevelopers, {
				routerPayload: { city: 1 },
				queryPayload: {
					per_page: 24,
          page: fresh ? 1 : (this.meta.current_page || 0) + 1,
          order_by: this.$route.query.order_by,
          direction: this.$route.query.direction
				}
			})
				.then((result) => {
          if (fresh) {
            this.infoDevelopers = result.response.items
          } else {
            this.infoDevelopers.push(...result.response.items)
          }
					this.meta = result.response.meta
				})
				.finally(() => {
					this.isLoading = false
          this.processingRequest = false
				})
		},
		redirect(event, payload) {
			if (event.target.type !== 'button')
				this.$router.push({
					name: 'SoloDeveloper',
					params: { slug: payload, lang: this.$store.state.uiLanguage }
				})
		}
	},
	beforeMount() {
		this.get()
	}
}
</script>
